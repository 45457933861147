<template>
  <router-view/>
</template>

<style lang="sass">
.reviews
  position: relative
  // width: 970px
  &__title-wrapper
    display: flex
    justify-content: space-between
    align-items: stretch
    margin-bottom: 84px
    &--left
      display: flex
      flex-direction: column
      width: 40%
    &--right
      width: 57%
      display: flex
      justify-content: space-between
      gap: 10px
  .section-title
    margin-top: 5px
  &-cards,
  &-sended-cards,
  &-reseived-cards
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr))
    grid-auto-rows: 1fr
    grid-column-gap: 20px
    margin-bottom: 30px
    grid-row-gap: 30px
    padding-left: 0
    list-style: none

.review-write
  position: relative
  width: 775px
  &__title-wrapper
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 30px
  .section-title
    margin-top: 5px
  .review
    display: flex
    justify-content: space-between
    flex-wrap: wrap
  .review-item
    max-width: 335px
    width: 100%
    margin-bottom: 30px
    &__title
      font-weight: 600
      font-size: 16px
      line-height: 19px
      margin-bottom: 10px
    &__subtitle
      font-size: 14px
      line-height: 16px
      color: #CBCBCB
    &__card
      border: 0.5px solid #CBCBCB
      box-sizing: border-box
      border-radius: 13px
    &__description
      font-size: 14px
      line-height: 18px
      padding: 16px 15px
      margin-bottom: 0
    &__area
      display: flex
      flex-direction: row-reverse
      justify-content: space-between
      height: 84px
      padding: 15px 20px
      border-top: 0.5px solid #CBCBCB
      input
        display: none
        &:checked
          & + label,
          & ~ label
            &:before
              content: url("~@/assets/images/svg/star-review-green.svg")
      label
        position: relative
        width: 48px
        cursor: pointer
        &:before
          content: url("~@/assets/images/svg/star-review.svg")
          position: absolute
        &:hover
          &:before
            content: url("~@/assets/images/svg/star-review-green.svg")
          & ~ label
            &:before
              content: url("~@/assets/images/svg/star-review-green.svg")
    &__impressions
      display: flex
      flex-direction: column
    &__textarea
      height: 120px
      width: 100%
      max-width: 310px
      overflow-x: auto
      border: 0.5px solid #CBCBCB
      border-radius: 13px
      caret-color: #1AC386
      font-size: 15px
      line-height: 19px
      padding: 16px 18px
      margin-bottom: 31px
      &:focus
        outline: none
    &__submit-btn
      width: 100%
      max-width: 310px
      height: 55px
      color: white
      font-weight: 600
      font-size: 16px
      line-height: 16px
      background: #1AC386
      border-radius: 10px
      &[disabled]
        background: #ffffff
        color: #1AC386
        border: 1px solid #1AC386
  &__appeal
    .section-subtitle
      font-size: 14px
      line-height: 16px
      color: #CBCBCB
      max-width: 335px
    .review-write__title-wrapper
      max-width: 400px
    ul
      list-style: none
      padding-left: 0
      margin-bottom: 0
    ::v-deep .reviews-sended-card .review-btn
      display: none


// 1080
@media screen and (max-width: 1440px)
  .reviews
    width: 794px
    &__title-wrapper
      &--left
        width: 20%
      &--right
        width: 70%
    &-cards,
    &-sended-cards,
    &-reseived-cards
      //grid-template-columns: repeat(2, 310px)

// 996
@media screen and (max-width: 1180px)
  .reviews
    width: 726px
    &__title-wrapper
      margin-bottom: 35px
      flex-wrap: wrap
      &--left
        width: 100%
        margin-bottom: 20px
      &--right
        width: 80%

  .review-write
    width: 726px

// 900
@media screen and (max-width: 1024px)
  .reviews
    width: 630px
    &__title-wrapper
      &--right
        width: 100%
  .review-write
    width: 530px
    .review
      justify-content: center

// 768
@media screen and (max-width: 912px)
  .reviews
    width: 100%

    box-sizing: border-box

    &__title-wrapper
    &-cards,
    &-sended-cards,
    &-reseived-cards
      //grid-template-columns: 1fr
      grid-template-rows: min-content
      justify-items: center
    &-card
      width: 100%
      max-width: 310px
</style>
